import './src/scss/custom.scss';
import {gsap} from "gsap";


let main = document.getElementById('main');
const anim = document.getElementById('logo-svg-anim');
const c1 = document.getElementById('ellipse1');
const c2 = document.getElementById('ellipse2');
const c3 = document.getElementById('ellipse3');
const target = {
    c1,
    c2,
    c3
};


let myAnim = gsap.timeline();


myAnim.from('#logo', {
    duration: 2,
    filter: "blur(10px)",
    opacity: 0,
    scale: 1.2,
    transformOrigin: "center center"
})

myAnim.from(c1, {
    transformOrigin: 'bottom center',
    rotation: 180
}, "<");

myAnim.from(c2, {
    transformOrigin: 'top center',
    rotation: -180,
}, "<25%");

myAnim.from(c3, {
    transformOrigin: 'center right',
    rotation: -180,
}, "<25%");